@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: hsl(0 0% 100%);
    --foreground: hsl(224 71.4% 4.1%);

    --card: hsl(0 0% 100%);
    --card-foreground: hsl(224 71.4% 4.1%);

    --popover: hsl(0 0% 100%);
    --popover-foreground: hsl(224 71.4% 4.1%);

    --primary: hsl(220.9 39.3% 11%);
    --primary-foreground: hsl(210 20% 98%);

    --secondary: hsl(220 14.3% 95.9%);
    --secondary-foreground: hsl(220.9 39.3% 11%);

    --tertiary: hsl(220 14.3% 95.9%);
    --tertiary-foreground: hsl(220.9 39.3% 11%);

    --muted: hsl(220 14.3% 95.9%);
    --muted-foreground: hsl(220 8.9% 46.1%);

    --accent: hsl(220 14.3% 95.9%);
    --accent-foreground: hsl(220.9 39.3% 11%);

    --destructive: #f04545;
    --destructive-foreground: hsl(210 20% 98%);

    --border: hsl(220 13% 91%);
    --input: hsl(220 13% 91%);
    --ring: hsl(224 71.4% 4.1%);

    --radius: 1rem;

    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
    --chart-1: 215 100% 60%;
    /* Blue */
    --chart-2: 0 84% 60%;
    /* Red */
    --chart-3: 142 72% 29%;
    /* Green */
  }

  .dark {
    --background: hsl(224, 56%, 5%);
    --foreground: hsl(210 20% 98%);

    --card: hsl(224 71.4% 4.1%);
    --card-foreground: hsl(210 20% 98%);

    --popover: hsl(224 71.4% 4.1%);
    --popover-foreground: hsl(210 20% 98%);

    --primary: hsl(210 20% 98%);
    --primary-foreground: hsl(220.9 39.3% 11%);

    --secondary: hsl(215 27.9% 16.9%);
    --secondary-foreground: hsl(210 20% 98%);

    --muted: hsl(215 27.9% 16.9%);
    --muted-foreground: hsl(217.9 10.6% 64.9%);

    --accent: rgba(3, 162, 231, 0.2);
    --accent-foreground: rgba(43, 172, 227, 0.855);

    --destructive: #e32d2d;
    --destructive-foreground: hsl(210 20% 98%);

    --border: hsl(215 27.9% 16.9%);
    --input: hsl(215 27.9% 16.9%);
    --ring: hsl(216 12.2% 83.9%);

    --sidebar-background: 218, 30%, 12%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;

    --hover-background: rgba(18, 102, 138, 0.168);
  }

  input {
    @apply border bg-secondary-foreground text-primary-foreground invalid:border-2 invalid:border-solid invalid:border-destructive;
  }
}

@layer base {
  * {
    @apply border-border;
    box-sizing: border-box;
  }

  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  .dev-only {
    @apply border-2 border-blue-500/50 box-border;
  }

  .dev-feature {
    @apply border-2 border-red-500/50 box-border;
  }
}

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading;
  }
}

@layer components {
  .markdown-content {
    @apply prose prose-invert max-w-none
    // Headers
    prose-h1:text-2xl prose-h1:font-bold prose-h1:mb-4
    prose-h2:text-xl prose-h2:font-semibold prose-h2:mb-3
    prose-h3:text-lg prose-h3:font-medium prose-h3:mb-2

    // Lists
    prose-ul:list-disc prose-ul:ml-4
    prose-ol:list-decimal prose-ol:ml-4

    // Code blocks
    prose-code:bg-grey-900 prose-code:rounded prose-code:px-1
    prose-pre:bg-transparent prose-pre:p-4 prose-pre:rounded-lg

    // Blockquotes
    prose-blockquote:border-l-4 prose-blockquote:border-gray-500 
    prose-blockquote:pl-4 prose-blockquote:italic

    // Links
    prose-a:text-blue-400 prose-a:hover:text-blue-300

    // Images
    prose-img:rounded-lg prose-img:max-w-full

    // Horizontal rules
    prose-hr:border-gray-700

    // Custom spacing
    prose-p:mb-4
    prose-ul:mb-4
    prose-ol:mb-4;

    code:not(pre code) {
      @apply bg-grey-800 px-1 rounded text-sm;
    }

    pre {
      code {
        @apply bg-grey-800 p-0;
      }
    }
  }
}
